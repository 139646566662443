.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}
// qtweb hooks
.loading-screen {
  -webkit-box-align: center;
  display: -webkit-box;
  -webkit-box-pack: center;
}
